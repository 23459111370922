import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Grid, Container, Typography, Box, CardMedia, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'
import { useStaticQuery, graphql } from 'gatsby'

const useStyles = makeStyles(theme =>({
    mainContainer:{
        margin: '1rem auto',
        padding: '0'
    },
    aboutMeBox:{
        border: `2px solid ${theme.palette.primary.dark}`,
        height: '100%',
        padding: '2rem',
        boxSizing: 'border-box',
        color: theme.palette.primary.dark
    },
    aboutMeTitle:{
        marginBottom:'1rem'
    },
    centralImage:{
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    quoteContainer1:{
        padding: '2rem',
        background: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        marginBottom: '1rem',
        boxSizing: 'border-box'
    },
    quoteContainer2:{
        padding: '2rem',
        background: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        boxSizing: 'border-box'
    },
    firstQuoteMark:{
        transform: 'rotate(180deg)'
    },
    quote:{
        fontStyle: 'italic !important'
    },
    quoteName:{
        marginTop: '1.5rem'
    }
}),{index: 1})

const AboutMe = () => {
    const classes = useStyles()
    const data = useStaticQuery(graphql`
    query aboutMe {
        strapiHome {
          Aboutme {
            Titulo
            aboutMeQuotes {
              Frase
              Nombre
            }
            descripcion
          }
          AboutMePicture {
            childImageSharp {
                fixed(width:400 , quality: 100) {
                  src
                }
            }
          }
        }
      }
  `)
    const descripcion =  data.strapiHome.Aboutme.descripcion ? 
                            data.strapiHome.Aboutme.descripcion.replace(/\n/, ' \n') : ""

    return (
        <Container className={classes.mainContainer} id="aboutme-section">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box className={classes.aboutMeBox}>
                        <Hidden smDown>
                            <Typography variant="h3" component="h2" className={classes.aboutMeTitle}>
                                { data.strapiHome.Aboutme.Titulo }
                            </Typography>
                        </Hidden>
                        <Hidden mdUp>
                            <Typography variant="h4" component="h2" className={classes.aboutMeTitle}>
                                { data.strapiHome.Aboutme.Titulo }
                            </Typography>
                        </Hidden>
                        <Typography variant="h6" component="div">
                            <ReactMarkdown>
                                { descripcion }
                            </ReactMarkdown>    
                        </Typography>
                    </Box>
                </Grid> 
                <Grid item xs={12} sm={6} md={4}>
                  {
                      data.strapiHome.AboutMePicture &&(
                        <CardMedia
                            component="img"
                            image={ data.strapiHome.AboutMePicture.childImageSharp.fixed.src }
                            alt= "profile-pic"
                            className={classes.centralImage}
                        />
                      ) 
                  }  
                </Grid>  
                <Grid item xs={12} md={4} className={classes.gridContainer3}>
                    {
                        data.strapiHome.Aboutme.aboutMeQuotes[0] && (
                            <Grid item xs={12}>
                                <Box className={classes.quoteContainer1}>
                                    <Typography variant="h6" component="div" className={classes.quote}>
                                        <FormatQuoteIcon className={classes.firstQuoteMark}/>
                                            { data.strapiHome.Aboutme.aboutMeQuotes[0].Frase }
                                        <FormatQuoteIcon />
                                    </Typography>
                                    <Typography className={classes.quoteName}>
                                        { data.strapiHome.Aboutme.aboutMeQuotes[0].Nombre }
                                    </Typography>
                                </Box>
                            </Grid>
                        )
                    }
                    {
                        data.strapiHome.Aboutme.aboutMeQuotes[1] && (
                            <Grid item xs={12}>
                                <Box className={classes.quoteContainer2}>
                                    <Typography variant="h6" component="div" className={classes.quote}>
                                        <FormatQuoteIcon className={classes.firstQuoteMark}/>
                                            { data.strapiHome.Aboutme.aboutMeQuotes[1].Frase }
                                        <FormatQuoteIcon />
                                    </Typography>
                                    <Typography className={classes.quoteName}>
                                        { data.strapiHome.Aboutme.aboutMeQuotes[1].Nombre }
                                    </Typography>
                                </Box>
                            </Grid>
                        )
                    }
                </Grid>     
            </Grid>
        </Container>
    )
}

export default AboutMe
