import React from 'react'
import SEO from '../components/Seo'
import Layout from '../components/Layout'
import HomeCarousel from '../components/home/HomeCarousel'
//import { Container } from "@material-ui/core"
import TimelineComponent  from '../components/home/TimelineComponent'
import AboutMe from '../components/home/AboutMe'
import Projects from '../components/home/Projects'

const IndexPage = () => {

  return(
    <Layout>
      <SEO title="Home" />
      <HomeCarousel />
      <AboutMe />
      <TimelineComponent />
      <Projects />
    </Layout>)
}

export default IndexPage
