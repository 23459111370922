import React from 'react'
import { Grid, 
         Container, 
         Typography, 
         Box, 
         CardMedia, 
         Hidden, 
         Zoom } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useStaticQuery, graphql } from 'gatsby'
import { NavigateNext as NavigateNextIcon } from '@material-ui/icons'
import Carousel from 'react-material-ui-carousel'


const useStyles = makeStyles(theme =>({
    mainContainer:{
        margin: '1rem auto',
        padding: '0'
    },
    projectListBox:{
        background: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        height: '100%',
        padding: '2rem',
        boxSizing: 'border-box',
    },
    projectList:{
        listStyle:'none',
        padding:'0',
        margin:'0',
        marginRight:'1rem',
    },
    descriptionBox:{
        padding: '2rem',
        boxSizing: 'border-box',
        color: theme.palette.primary.dark,
        maxHeight: '650px',
        height: '100%',
        position:'relative'
    },
    projectImage:{
        position: 'relative',
        zIndex: '10'
    },
    imgContainer:{
        width:'100%',
        height:'auto',
    },
    imgContainerBox:{
        position:'relative',
        width:'100%',
        height:'100%'
    },
    textCarousel:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        textAlignt:'center',
        height: '70px',
    },
    projectItemListButton:{
        color: theme.palette.primary.contrastText,
        cursor:'pointer'
    }
}),{index: 1})

const Projects = () => {
    //Styles
    const classes = useStyles()
    const [ active, setActive ] = React.useState(0)
    //Gatsby Query
    const data = useStaticQuery(graphql`
    query workQuery {
        strapiHome {
            Work {
            Imagen {
                childImageSharp {
                    fixed(width:1280 , quality: 100) {
                      src
                    }
                }
            }
            Link
            Titulo
            id
            }
            worktitle
        } 
    }  
  `)
    //console.log(active)
    //console.log()
    return data.strapiHome.Work &&(
        <Container className={classes.mainContainer} id="mywork-section">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4}>
                    <Box className={classes.projectListBox}>
                        <Hidden smDown>
                            <Typography variant="h3" component="h2">
                                { data.strapiHome.worktitle }
                            </Typography>
                        </Hidden>
                        <Hidden mdUp>
                            <Typography variant="h4" component="h2"  align="center">
                                { data.strapiHome.worktitle }
                            </Typography>
                        </Hidden>
                        <Hidden mdUp>
                            <Carousel 
                                indicators={false} 
                                autoPlay={false} 
                                className="work-mobile-carousel"
                                navButtonsAlwaysVisible={true}
                                index={active}
                                onChange={(index)=>{setActive(index)}}
                            >
                                {
                                    data.strapiHome.Work.map((workitem,index)=>(
                                        <div key={ index } className={ classes.textCarousel }>
                                            <Typography variant="h6" component="span" data-content={ workitem.Titulo }>
                                                { workitem.Titulo }
                                            </Typography>  
                                        </div> 
                                    ))
                                }   
                            </Carousel>
                        </Hidden>
                        <Hidden smDown>
                            <ul className={classes.projectList}>
                                {
                                    data.strapiHome.Work.map((workitem,index)=>(
                                        <li className={`projectListItem${active === index ? ' active': ''}`} 
                                            key={index}
                                        >
                                            <button onMouseOver={() =>{setActive(index)}}
                                                    onFocus={() =>{setActive(index)}} 
                                                    onClick={() =>{setActive(index)}}
                                                    className ={classes.projectItemListButton}
                                            >
                                                <Typography variant="h6" component="span" data-content={ workitem.Titulo }>
                                                    { workitem.Titulo }
                                                </Typography>  
                                                <NavigateNextIcon className="projectListItemIcon" /> 
                                            </button>
                                        </li>
                                    ))                                
                                }
                            </ul>
                        </Hidden>
                    </Box>                    
                </Grid> 
                <Grid item xs={12} sm={12} md={8}>
                    <Box className={classes.descriptionBox}>
                        <Box className= {classes.imgContainerBox}>
                            {
                                data.strapiHome.Work.map((workitem,index)=>(
                                    <Zoom in={ active === index ? true : false } 
                                          className={classes.imgContainer} 
                                          style={ active === index ? {display:'block'}: {display:'none'} } 
                                          key={index}
                                    >
                                        <a href={workitem.Link}>
                                            <CardMedia
                                                component="img"
                                                image= {workitem.Imagen.childImageSharp.fixed.src}
                                                alt= {`image-${index}`}
                                                className={classes.projectImage}
                                            />
                                        </a>
                                    </Zoom>
                                ))
                            }

                        </Box>
                    </Box> 
                </Grid>  
            </Grid>    
        </Container>
    )
}

export default Projects
