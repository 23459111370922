import React from 'react'
import { VerticalTimeline, VerticalTimelineElement}  from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import { Work as WorkIcon, School as SchoolIcon, Star as StarIcon } from '@material-ui/icons'
import { Box, Container, Typography, Hidden } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useStaticQuery, graphql } from 'gatsby'
import TimelineTech from './TimelineTech'


const useStyles = makeStyles(theme => (  {
  timelineBackground:{
    background:theme.palette.gray.background,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  heroContainer:{
    background: theme.palette.primary.main,
    textAlign: 'center',
    padding:'1.5rem 0',
    color: theme.palette.primary.contrastText,
  }
}),{index: 1})

const TimelineComponent = () => {

    const classes = useStyles()
    const theme = useTheme()
    const data = useStaticQuery(graphql`
    query getTimeline {
      strapiHome {
        timeline {
          descripcion
          fecha
          id
          subtitulo
          tipo
          titulo
          ultimo
          tecnologias {
            techPicker
          }
        }
      }
    }
  `)
    return (
      <div id="timeline-section">
        <Box className={ classes.heroContainer }>
        <Container>
          <Hidden smDown>
            <Typography 
              variant="h3" 
              type="h2"
              color="inherit"
            >
              Experience & Achievements
            </Typography>
          </Hidden>
          <Hidden mdUp>
            <Typography 
              variant="h4" 
              type="h2"
              color="inherit"
            >
              Experience & Achievements
            </Typography>
          </Hidden>
        </Container>
      </Box>
      <Box className={ classes.timelineBackground }>
        <Container>
          <VerticalTimeline>
            {
              data.strapiHome.timeline &&(
                data.strapiHome.timeline.slice(0).reverse().map((timelineEvent,index) => {
                  switch(timelineEvent.tipo){
                    case 'trabajo':
                      return(!!timelineEvent.ultimo ?
                        <VerticalTimelineElement
                          className="vertical-timeline-element--work"
                          //contentStyle={{ background: theme.timeline.color.job, color: theme.palette.primary.contrastText }}
                          //contentArrowStyle={{ borderRight: `7px solid ${ theme.timeline.color.job }` }}
                          contentStyle = {{borderTop: `3px solid ${theme.timeline.color.job}`, color: theme.palette.primary.dark }}
                          date={ timelineEvent.fecha }
                          iconStyle={{ background: theme.timeline.color.job, color: theme.palette.primary.contrastText }}
                          icon={<WorkIcon />}
                          key={index}
                        >
                          <h3 className="vertical-timeline-element-title">{ timelineEvent.titulo }</h3>
                          <h4 className="vertical-timeline-element-subtitle">{ timelineEvent.subtitulo }</h4>
                          <p>
                            { timelineEvent.descripcion }
                          </p>
                          <TimelineTech technologies={timelineEvent.tecnologias}/>
                        </VerticalTimelineElement>
                          :
                        <VerticalTimelineElement
                          className="vertical-timeline-element--work"
                          date={ timelineEvent.fecha }
                          contentStyle = {{borderTop: `3px solid ${theme.timeline.color.job}`, color: theme.palette.primary.dark }}
                          iconStyle={{ background: theme.timeline.color.job , color: theme.palette.primary.contrastText }}
                          icon={<WorkIcon />}
                          key={index}
                        >
                          <h3 className="vertical-timeline-element-title">{ timelineEvent.titulo }</h3>
                          <h4 className="vertical-timeline-element-subtitle">{ timelineEvent.subtitulo }</h4>
                          <p>
                            { timelineEvent.descripcion }
                          </p>
                          <TimelineTech technologies={timelineEvent.tecnologias}/>
                        </VerticalTimelineElement>)
                    case 'educacion':
                      return(!!timelineEvent.ultimo ?
                        <VerticalTimelineElement
                          className="vertical-timeline-element--education"
                          //contentStyle={{ background: theme.timeline.color.achievement, color: theme.palette.primary.contrastText }}
                          //contentArrowStyle={{ borderRight: `7px solid ${ theme.timeline.color.achievement }` }}
                          contentStyle = {{borderTop: `3px solid ${theme.timeline.color.achievement}`, color: theme.palette.primary.dark }}
                          date={ timelineEvent.fecha }
                          iconStyle={{ background: theme.timeline.color.achievement, color: theme.palette.primary.contrastText }}
                          icon={<SchoolIcon />}
                          key={index}
                        >
                          <h3 className="vertical-timeline-element-title">{ timelineEvent.titulo }</h3>
                          <h4 className="vertical-timeline-element-subtitle">{ timelineEvent.subtitulo }</h4>
                          <p>
                            { timelineEvent.descripcion }
                          </p>
                          <TimelineTech technologies={timelineEvent.tecnologias}/>
                        </VerticalTimelineElement> 
                          :
                        <VerticalTimelineElement
                          className="vertical-timeline-element--education"
                          date={ timelineEvent.fecha }
                          contentStyle = {{borderTop: `3px solid ${theme.timeline.color.achievement}`, color: theme.palette.primary.dark }}
                          iconStyle={{ background: theme.timeline.color.achievement , color: theme.palette.primary.contrastText }}
                          icon={<SchoolIcon />}
                          key={index}
                        >
                          <h3 className="vertical-timeline-element-title">{ timelineEvent.titulo }</h3>
                          <h4 className="vertical-timeline-element-subtitle">{ timelineEvent.subtitulo }</h4>
                          <p>
                            { timelineEvent.descripcion }
                          </p>
                          <TimelineTech technologies={timelineEvent.tecnologias}/>
                        </VerticalTimelineElement>
                      )
                    default:
                      return null
                  }
                })
              )
            }
            <VerticalTimelineElement
              iconStyle={{ background: theme.timeline.color.start , color: theme.palette.primary.contrastText }}
              icon={<StarIcon />}
            />
          </VerticalTimeline>
        </Container>
      </Box>
    </div>  
    )
}

export default TimelineComponent
