import React from 'react'
import Carousel from 'react-material-ui-carousel'
import {Paper, 
        Button,  
        CardMedia, 
        Typography,
        Container,
        Hidden} from '@material-ui/core'
import { useStaticQuery, graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme =>({
    image:{
        width:'100%',
        height:'100%',
        position: 'absolute',
        objectFit: 'cover',
        top: '0',
        left: '0',
        zIndex:'0',
    },
    paper:{
        background: 'transparent',
        border: 'none',
        boxShadow: 'none',
        position:'relative',
        height:`100vh`
    },
    paperMobile:{
        background: 'transparent',
        border: 'none',
        boxShadow: 'none',
        position:'relative',
        height:`50vh`
    },
    divZindex:{
        zIndex: '10',
        position: 'relative',
        height: '100%',
        background :'rgba(0,0,0,0.4)'
    },
    innerContent:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexFlow:'column',
        height:'100%',
        color:theme.palette.primary.contrastText,
        textAlign:'center'
    },
    indicators:{
        position: 'absolute',
        bottom: 0,
        zIndex: 20,
    },
    carouselButtonDesktop:{
        marginTop: '1rem',
    },
    carouselButtonMobile:{
        marginTop: '.5rem',
    },
  }),{index: 1})

const HomeCarousel = () => {
    /* documentation for home carousel https://github.com/Learus/react-material-ui-carousel */
    const classes = useStyles()

    const data = useStaticQuery(graphql`
    query sliderQuery {
      strapiHome {
        slides {
            Boton {
              Titulo
              Enlace
            }
            Subtitulo
            Titulo
            Imagen {
                childImageSharp {
                    fixed(width:1290) {
                      src
                    }
                }
            }
          }
        }
    }
  `)

    return data.strapiHome.slides && (
        <div id="Home-carousel" >
            <Hidden mdUp>
                <Carousel 
                    indicators
                > 
                    {
                        data.strapiHome.slides.map((slides,index) =>(
        
                            <Paper key={ index } className={classes.paperMobile}>
                                <CardMedia
                                    component="img"
                                    image={slides.Imagen.childImageSharp.fixed.src}
                                    alt={`slide-${index}`}
                                    className={ classes.image }
                                />
                                <div className={ classes.divZindex }>
                                    <Container className={classes.innerContent}>
                                        <Typography 
                                            variant="h3" 
                                            component='h2'
                                        > 
                                            { slides.Titulo } 
                                        </Typography> 
                                        {
                                            slides.Subtitulo && (
                                                <Typography variant="h5" component='p'> { slides.Subtitulo } </Typography>                                         
                                            )
                                        }
                                        {
                                            slides.Boton &&(
                                                <Button 
                                                    variant="contained" 
                                                    color="secondary"
                                                    className={classes.carouselButtonMobile}
                                                    href={slides.Boton.Enlace}
                                                >
                                                    {slides.Boton.Titulo}
                                                </Button>
                                            )
                                        }
                                    </Container>
                                </div>    
                            </Paper>
                        ))
                    }      
                </Carousel>
            </Hidden>
            <Hidden smDown>
                <Carousel 
                    indicators={ false }
                    navButtonsAlwaysVisible
                > 
                    {
                        data.strapiHome.slides.map((slides,index) =>(
        
                            <Paper key={ index } className={classes.paper}>
                                <CardMedia
                                    component="img"
                                    image={slides.Imagen.childImageSharp.fixed.src}
                                    alt={`slide-${index}`}
                                    className={ classes.image }
                                />
                                <div className={ classes.divZindex }>
                                    <div className={classes.innerContent}>
                                        <Typography 
                                            variant="h2" 
                                            component='h2'
                                        > 
                                            { slides.Titulo } 
                                        </Typography> 
                                        {
                                            slides.Subtitulo && (
                                                <Typography variant="h4" component='p'> { slides.Subtitulo } </Typography>                                         
                                            )
                                        }
                                        {
                                            slides.Boton &&(
                                                <Button 
                                                    variant="contained" 
                                                    color="secondary" 
                                                    href={slides.Boton.Enlace}
                                                    className={classes.carouselButtonDesktop}
                                                >
                                                    {slides.Boton.Titulo}
                                                </Button>
                                            )
                                        }
                                    </div>
                                </div>    
                            </Paper>
                        ))
                    }      
                </Carousel>
            </Hidden>
        </div>
    )
}

export default HomeCarousel
