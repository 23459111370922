import React from 'react'
import { Grid } from '@material-ui/core'
import {DiReact, 
        DiDrupal, 
        DiCss3, 
        DiHtml5, 
        DiJavascript1, 
        DiDotnet, 
        DiJqueryLogo, 
        DiSass,
        DiLess,
        DiSymfony,
        DiPhp,} from "react-icons/di"
import SitecoreIcon from '../../images/sitecore-icon.svg'
import VisualBasicIcon from '../../images/vb-icon.svg'
import PimcoreIcon from '../../images/pimcore-icon.svg'
import GatsbyIcon from '../../images/gtsby-icon.svg'

import { useTheme, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    gridContainer:{
        maxWidth: '350px',
    },
    technologies:{
        fontSize: '12px !important',
        fontStyle: 'italic !important',
        color: `${theme.palette.primary.light} !important`,
        fontWeight: '600 !important',
        marginBottom: '10px !important'
    }
}),{index: 1})

const TimelineTech = (props) => {
    const theme = useTheme()
    const classes = useStyles()
    /*
    react #00d8ff
    sitecore
    drupal
    css
    html
    javascript #f1bf22
    dotnet #1384c8
    visualbasic #407fe1
    pimcore #624299
    jquery #0769ad
    sass #d56fa4
    less #214674
    symfony #1a171b
    php #9aa0c6
    gatsby
    */
    return props.technologies.length ? (
        <>
        <p className={classes.technologies}>Technologies used:</p>
        <Grid container spacing={3} className={classes.gridContainer}>
            { 
                props.technologies.map( tech => {
                    switch(tech.techPicker){

                        case 'react':
                            return(
                            <Grid item xs={2} key={tech.techPicker}>
                                <a href="https://reactjs.org/">
                                    <DiReact style={{ fill:'#00d8ff'}} size={theme.timeline.icons.height} />
                                </a>    
                            </Grid>) 
                        case 'sitecore':
                            return(
                            <Grid item xs={2} key={tech.techPicker}>
                                <a href="https://www.sitecore.com/">
                                    <SitecoreIcon 
                                    style={{ height: theme.timeline.icons.height,
                                            width : theme.timeline.icons.height }} 
                                    />
                                </a>
                            </Grid>)
                        case 'drupal':
                            return(
                            <Grid item xs={2} key={tech.techPicker}>
                                <a href="https://www.drupal.org/">
                                    <DiDrupal style={{ fill:'#0d669d' }} size={theme.timeline.icons.height} />
                                </a>
                            </Grid>)
                        case 'css':
                            return(
                            <Grid item xs={2} key={tech.techPicker}>
                                <a href="https://www.w3schools.com/css/css_intro.asp">
                                    <DiCss3 style={{ fill:'#3bb2e0' }} size={theme.timeline.icons.height} />
                                </a>
                            </Grid>) 
                        case 'html':
                            return(
                            <Grid item xs={2} key={tech.techPicker}>
                                <a href="https://www.w3schools.com/html/html_intro.asp">
                                    <DiHtml5 style={{ fill:'#fb7a5a' }} size={theme.timeline.icons.height} />
                                </a>
                            </Grid>)  
                        case 'javascript':
                            return(
                            <Grid item xs={2} key={tech.techPicker}>
                                <a href="https://www.javascript.com/">
                                    <DiJavascript1 style={{ fill:'#f1bf22' }} size={theme.timeline.icons.height} />
                                </a>
                            </Grid>)
                        case 'dotnet':
                            return(
                            <Grid item xs={2} key={tech.techPicker}>
                                <a href="https://dotnet.microsoft.com/apps/aspnet">
                                    <DiDotnet style={{ fill:'#1384c8' }} size={theme.timeline.icons.height} />
                                </a>
                            </Grid>) 
                        case 'visualbasic':
                            return(
                            <Grid item xs={2} key={tech.techPicker}>
                                <a href="https://docs.microsoft.com/en-us/dotnet/visual-basic/">
                                    <VisualBasicIcon  
                                        style={{ 
                                                fill:'#407fe1', 
                                                height: theme.timeline.icons.height,
                                                width: theme.timeline.icons.height,
                                            }} 
                                    />
                                </a>
                            </Grid>) 
                        case 'pimcore':
                            return(
                            <Grid item xs={2} key={tech.techPicker}>
                                <a href="https://pimcore.com/en">
                                    <PimcoreIcon  
                                        style={{ 
                                                fill:'#624299', 
                                                height: theme.timeline.icons.height,
                                                width: theme.timeline.icons.height,
                                            }} 
                                    />
                                </a>
                            </Grid>)
                        case 'jquery':
                            return(
                            <Grid item xs={2} key={tech.techPicker}>
                                <a href="https://jquery.com/">
                                    <DiJqueryLogo style={{ fill:'#0769ad' }} size={theme.timeline.icons.height} />
                                </a>
                            </Grid>)
                        case 'sass':
                            return(
                            <Grid item xs={2} key={tech.techPicker}>
                                <a href="https://sass-lang.com/">
                                    <DiSass style={{ fill:'#d56fa4' }} size={theme.timeline.icons.height} />
                                </a>
                            </Grid>)
                        case 'less':
                            return(
                            <Grid item xs={2} key={tech.techPicker}>
                                <a href="http://lesscss.org/">
                                    <DiLess style={{ fill:'#214674' }} size={theme.timeline.icons.height} />
                                </a>
                            </Grid>)
                        case 'symfony':
                            return(
                            <Grid item xs={2} key={tech.techPicker}>
                                <a href="https://twig.symfony.com/">
                                    <DiSymfony style={{ fill:'#1a171b' }} size={theme.timeline.icons.height}/>
                                </a>
                            </Grid>)
                        case 'php':
                            return(
                            <Grid item xs={2} key={tech.techPicker}>
                                <a href="https://www.php.net/manual/en/intro-whatis.php">
                                    <DiPhp style={{ fill:'#000' }} size={theme.timeline.icons.height} />
                                </a>
                            </Grid>)
                        case 'gatsby':
                            return(
                            <Grid item xs={2} key={tech.techPicker}>
                                <a href="https://www.gatsbyjs.com/">
                                    <GatsbyIcon  
                                        style={{ 
                                                fill:'#7b90ca', 
                                                height: theme.timeline.icons.height,
                                                width: theme.timeline.icons.height,
                                            }} 
                                    />
                                </a>
                            </Grid>)                                                                                                                                                                                                                                                    
                        default:
                            return null    
                    }

                })
            }
        </Grid>
        </>
    ) : null
}

export default TimelineTech
